<template>
  <div>
    <page-header
      :title="'Admins'"
      :sub-heading="true"
      :sub-header="'Dashboard'"
      :link-name="'Admin'"
      :link-url="'/admins'"
    >
        <ca-button @click.native="addRole" :type="'ghost'" class="mr-1">Create Roles</ca-button>
        <ca-button @click.native="addUser">Add User</ca-button>
    </page-header>
    
    <full-page-content-container>
       <div class="col-xs-12 pb">
        <div class="usp__nav tab-nav">
          <router-link
            :to="{name: 'admins'}"
            class="tab-nav__el"
            exact
            >Users
          </router-link>
          <router-link
            :to="{name: 'admins-roles'}"
            class="tab-nav__el"
            exact
            >Roles
          </router-link> 
        </div>
      </div>

      <ca-popup
        :header="'Delete User'"
        :toggle="openDeletePopup"
        @open="confirmDelete"
        @close="cancelDelete"
        v-show="currentUser !== null"
      >
        <template v-slot:content>
          Are you sure you want to delete the user?
        </template>
        <template v-slot:actions>
          <ca-button @click.native="deleteUser">Yes</ca-button>
        </template>
      </ca-popup>

      <grid-table :column-defs="columnDefs" :row-data="rowData" :fetching-data="fetchingData" v-if="rowData.length > 0">
        
        <template v-slot:cell-renderer="slotProps">
              <div>
                <span
                  class="edit-icon"
                  @click="edit(slotProps.currentRowData.id)"
                  ><img
                    :src="require('@/assets/images/icons/edit.svg')"
                    alt="edit icon"
                /></span>
                <span class="delete-icon"
                  @click="confirmDelete(slotProps.currentRowData)"
                  ><img
                    :src="require('@/assets/images/icons/delete.svg')"
                    alt="delete icon"
                /></span>
              </div>
            </template>
      </grid-table>
    </full-page-content-container>
  </div>
</template>

<script>
import axios from "../../../axios";
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import CaButton from "../../components/buttons/CaButton";
// import subscriptios from "../../db/subscriptios";
import GridTable from "../../components/tables/GridTable";
import CaPopup from "../../components/popup/CaPopup";
export default {
  name: "Admins",
  components: { PageHeader, FullPageContentContainer, GridTable, CaButton, CaPopup },
  data() {
    return {
       columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Name",
          field: "name",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Email",
          field: "email",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Role",
          field: "role",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Last Login",
          field: "session",
          filter: true,
          sortable: true,
        },
         {
          headerName: "Aaction",
          field: "actions",
          hasCellRendererFramework: true,
        },
    
      ],
      rowData: [],
      openDeletePopup: false,
      currentUser: null,
      fetchingData: false,
      cellRendererAction: "cell-renderer-action",
    }
  },
  methods:{
    addUser(){
      this.$router.push({ name: 'admin-new'})
    },

    addRole(){
      this.$router.push({ name: 'add-role'})
    },
    edit(id){
      this.$router.push({ name: 'admin-edit', params: { id: id }})
    },
    getUser(){
        const loading = this.$vs.loading();
      this.fetchingData = true;
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios
        .get(`${this.$store.state.server.requestUri}/admin/users`)
        .then((res) => {
          let data = res.data.data;
           res.data.data.map(user=>{
            user.name = user.first_name? user.first_name +" "+user.last_name: user.name
          })
          data = data.filter( (admin) => {
            return admin.role == 'Administrator';
          })
          if (data.length > 0) {
            console.log(this.rowData);
            this.rowData = data;
          } else {
            console.log("No Admin data");
          }
          this.fetchingData = false;
          loading.close();
      })

    },
    roles(){
      this.$router.push({ name: 'admins-roles'})
    },
    admins(){
      this.$router.push({ name: 'admins'})
    },

    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete(popUpData = null) {
      if (popUpData !== null) {
        this.currentUser = popUpData;
      }
      this.openDeletePopup = true;
    },
    deleteUser() {
      console.log(this.currentUser);
      // this.cancelDelete();
      const loading = this.$vs.loading();
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/user/deactivate/${this.currentUser.id}`, {
            'resource_id': this.currentUser.id,
          }
        )
        .then((res) => {
          loading.close();
          this.$toast(
            `User with id ${this.currentUser.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting user`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },
  },
  created() {
    this.getUser();

  },
};
</script>

<style scoped>
.pb{
  padding-bottom: 2em;
}
.edit-icon {
  margin-right: 0.8rem;
}

span:hover {
  cursor: pointer;
}
</style>
